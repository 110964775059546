// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-uteam-js": () => import("./../../../src/pages/uteam.js" /* webpackChunkName: "component---src-pages-uteam-js" */),
  "component---src-pages-vacancies-back-end-razrabotchik-php-js": () => import("./../../../src/pages/vacancies/back-end-razrabotchik-php.js" /* webpackChunkName: "component---src-pages-vacancies-back-end-razrabotchik-php-js" */),
  "component---src-pages-vacancies-js": () => import("./../../../src/pages/vacancies.js" /* webpackChunkName: "component---src-pages-vacancies-js" */),
  "component---src-pages-vacancies-junior-nodejs-razrabotchik-js": () => import("./../../../src/pages/vacancies/junior-nodejs-razrabotchik.js" /* webpackChunkName: "component---src-pages-vacancies-junior-nodejs-razrabotchik-js" */),
  "component---src-pages-vacancies-menedzher-dizajner-shablonov-js": () => import("./../../../src/pages/vacancies/menedzher-dizajner-shablonov.js" /* webpackChunkName: "component---src-pages-vacancies-menedzher-dizajner-shablonov-js" */),
  "component---src-pages-vacancies-pr-menedzher-1-js": () => import("./../../../src/pages/vacancies/pr-menedzher1.js" /* webpackChunkName: "component---src-pages-vacancies-pr-menedzher-1-js" */),
  "component---src-pages-vacancies-pr-menedzher-js": () => import("./../../../src/pages/vacancies/pr-menedzher.js" /* webpackChunkName: "component---src-pages-vacancies-pr-menedzher-js" */),
  "component---src-pages-vacancies-programmist-perl-js": () => import("./../../../src/pages/vacancies/programmist-perl.js" /* webpackChunkName: "component---src-pages-vacancies-programmist-perl-js" */),
  "component---src-pages-vacancies-sborka-sajtov-na-konstruktore-sajtov-ukit-js": () => import("./../../../src/pages/vacancies/sborka-sajtov-na-konstruktore-sajtov-ukit.js" /* webpackChunkName: "component---src-pages-vacancies-sborka-sajtov-na-konstruktore-sajtov-ukit-js" */),
  "component---src-pages-vacancies-sborshik-sajtov-na-konstruktorah-ucozuweb-js": () => import("./../../../src/pages/vacancies/sborshik-sajtov-na-konstruktorah-ucozuweb.js" /* webpackChunkName: "component---src-pages-vacancies-sborshik-sajtov-na-konstruktorah-ucozuweb-js" */),
  "component---src-pages-vacancies-senior-nodejs-engineer-js": () => import("./../../../src/pages/vacancies/senior-nodejs-engineer.js" /* webpackChunkName: "component---src-pages-vacancies-senior-nodejs-engineer-js" */),
  "component---src-pages-vacancies-specialist-po-seo-optimizacii-sajtov-js": () => import("./../../../src/pages/vacancies/specialist-po-seo-optimizacii-sajtov.js" /* webpackChunkName: "component---src-pages-vacancies-specialist-po-seo-optimizacii-sajtov-js" */),
  "component---src-pages-vacancies-specialist-sluzhby-tehnicheskoj-podderzhki-1-js": () => import("./../../../src/pages/vacancies/specialist-sluzhby-tehnicheskoj-podderzhki1.js" /* webpackChunkName: "component---src-pages-vacancies-specialist-sluzhby-tehnicheskoj-podderzhki-1-js" */),
  "component---src-pages-vacancies-specialist-sluzhby-tehnicheskoj-podderzhki-js": () => import("./../../../src/pages/vacancies/specialist-sluzhby-tehnicheskoj-podderzhki.js" /* webpackChunkName: "component---src-pages-vacancies-specialist-sluzhby-tehnicheskoj-podderzhki-js" */),
  "component---src-pages-vacancies-technology-evangelistdeveloper-advocate-js": () => import("./../../../src/pages/vacancies/technology-evangelistdeveloper-advocate.js" /* webpackChunkName: "component---src-pages-vacancies-technology-evangelistdeveloper-advocate-js" */)
}

